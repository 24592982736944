import { timestampDate } from '@bufbuild/protobuf/wkt';

import { formatDate, serviceAccountResourceName } from '../../helpers/util';
import { useGetServiceAccount } from '../../hooks/use_iam_api';
import { ServiceAccountStateBadge } from './service_account_state_badge';

interface ServiceAccountInfoProps {
  projectId: string;
  accountId: string;
}

export function ServiceAccountInfo(props: ServiceAccountInfoProps) {
  const name = serviceAccountResourceName(props.projectId, props.accountId);
  const { data } = useGetServiceAccount({ name: name });
  const acct = data;

  return (
    <>
      <div className="mt-4 max-w-5xl overflow-hidden border-t border-b border-gray-200">
        <div className="px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">State</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <ServiceAccountStateBadge state={acct.state} />
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Description</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {acct.description}
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Created</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {formatDate(timestampDate(acct.createTime))}
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Updated</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {formatDate(timestampDate(acct.updateTime))}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      <div className="mt-8">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          JSON Web Key Set (JWKS)
        </h3>
      </div>
      <div className="mt-3 overflow-hidden border border-gray-200 bg-white shadow-sm sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <div className="overflow-x-auto whitespace-pre font-mono text-sm text-gray-900">
            <div>{JSON.stringify(JSON.parse(acct.jwks), null, 2)}</div>
          </div>
        </div>
      </div>
    </>
  );
}
