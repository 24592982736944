import { timestampDate } from '@bufbuild/protobuf/wkt';

import { formatDate, projectResourceName } from '../../helpers/util';
import { useListServiceAccounts } from '../../hooks/use_iam_api';
import { routes } from '../../router';
import { PaginationFooter } from '../core/pagination_footer';
import { ServiceAccountKebab } from './service_account_kebab';
import { ServiceAccountStateBadge } from './service_account_state_badge';

interface ServiceAccountTableProps {
  projectId: string;
}

export function ServiceAccountTable(props: ServiceAccountTableProps) {
  const parent = projectResourceName(props.projectId);
  const { data, mutate } = useListServiceAccounts({
    parent: parent,
    pageSize: 15,
    pageToken: '',
  });
  return (
    <div className="mt-4 flex flex-col">
      <div className="-my-2 overflow-x-auto py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="inline-block min-w-full overflow-hidden align-middle">
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                  Name
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                  State
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                  Description
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                  Created
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {data.serviceAccounts.length == 0 && (
                <tr>
                  <td
                    colSpan={5}
                    className="whitespace-nowrap border-b border-gray-200 px-6 py-3 text-sm leading-5 text-gray-500"
                  >
                    No service accounts to display
                  </td>
                </tr>
              )}
              {data.serviceAccounts.map((acct) => (
                <tr key={acct.name}>
                  <td className="py2 whitespace-nowrap border-b border-gray-200 px-6 text-sm font-medium leading-5 text-gray-900">
                    <a
                      {...routes.serviceAccountDetail({
                        projectId: props.projectId,
                        serviceAccountId: acct.name.split('/')[3],
                      }).link}
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      {acct.name.split('/')[3]}
                    </a>
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm leading-5 text-gray-500">
                    <ServiceAccountStateBadge state={acct.state} />
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
                    <span>{acct.description}</span>
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
                    {formatDate(timestampDate(acct.createTime))}
                  </td>
                  <td className="w-full border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
                    <ServiceAccountKebab
                      projectId={props.projectId}
                      accountId={acct.name.split('/')[3]}
                      mutate={mutate}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {data.serviceAccounts.length > 0 && (
            <PaginationFooter
              totalSize={data.totalSize}
              pageSize={data.serviceAccounts.length}
              startIndex={data.startIndex}
              previousPageLink={routes.serviceAccountList({
                projectId: props.projectId,
                pageToken: data.previousPageToken,
              }).link.href}
              nextPageLink={routes.serviceAccountList({
                projectId: props.projectId,
                pageToken: data.nextPageToken,
              }).link.href}
            />
          )}
        </div>
      </div>
    </div>
  );
}
