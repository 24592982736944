import { timestampDate } from '@bufbuild/protobuf/wkt';

import {
  formatDate,
  oidcAccessTypeToString,
  oidcProviderResourceName,
} from '../../helpers/util';
import { useGetIdentityProvider } from '../../hooks/use_iam_api';
import { OIDCProviderStateBadge } from './oidc_provider_state_badge';

interface OIDCProviderInfoProps {
  projectId: string;
  identityProviderId: string;
}

export function OIDCProviderInfo(props: OIDCProviderInfoProps) {
  const name = oidcProviderResourceName(
    props.projectId,
    props.identityProviderId,
  );
  const { data } = useGetIdentityProvider({ name: name });
  const idp = data;

  return (
    <>
      <div className="mt-4 max-w-5xl overflow-hidden border-t border-b border-gray-200">
        <div className="px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">State</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <OIDCProviderStateBadge state={idp.state} />
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Issuer URI</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {idp.oidc.issuerUri}
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Discovery type
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <span className="inline-flex items-center rounded bg-gray-100 px-2 py-0.5 text-xs font-medium leading-4 text-gray-800">
                  {oidcAccessTypeToString(idp.oidc.accessType)}
                </span>
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Created</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {formatDate(timestampDate(idp.createTime))}
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Updated</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {formatDate(timestampDate(idp.updateTime))}
              </dd>
            </div>
          </dl>
        </div>
      </div>
      {idp.oidc.jwks !== '{}' && (
        <>
          <div className="mt-8">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              JSON Web Key Set (JWKS)
            </h3>
          </div>
          <div className="mt-3 overflow-hidden border border-gray-200 bg-white shadow-sm sm:rounded-lg">
            <div className="px-4 py-5 sm:px-6">
              <div className="overflow-x-auto whitespace-pre font-mono text-sm text-gray-900">
                <div>{JSON.stringify(JSON.parse(idp.oidc.jwks), null, 2)}</div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
