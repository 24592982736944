// @generated by protoc-gen-es v2.2.3 with parameter "target=ts"
// @generated from file annealing/iam/v1/resources.proto (package annealing.iam.v1, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_google_api_resource } from "../../../google/api/resource_pb";
import type { Timestamp } from "@bufbuild/protobuf/wkt";
import { file_google_protobuf_timestamp } from "@bufbuild/protobuf/wkt";
import type { IdentityProviderState, OidcAccessType, Role, RoleBindingState, ServiceAccountState } from "./enums_pb";
import { file_annealing_iam_v1_enums } from "./enums_pb";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file annealing/iam/v1/resources.proto.
 */
export const file_annealing_iam_v1_resources: GenFile = /*@__PURE__*/
  fileDesc("CiBhbm5lYWxpbmcvaWFtL3YxL3Jlc291cmNlcy5wcm90bxIQYW5uZWFsaW5nLmlhbS52MSLCAgoQSWRlbnRpdHlQcm92aWRlchIMCgRuYW1lGAEgASgJEjYKBXN0YXRlGAIgASgOMicuYW5uZWFsaW5nLmlhbS52MS5JZGVudGl0eVByb3ZpZGVyU3RhdGUSJAoEb2lkYxgDIAEoCzIWLmFubmVhbGluZy5pYW0udjEuT2lkYxIvCgtjcmVhdGVfdGltZRgEIAEoCzIaLmdvb2dsZS5wcm90b2J1Zi5UaW1lc3RhbXASLwoLdXBkYXRlX3RpbWUYBSABKAsyGi5nb29nbGUucHJvdG9idWYuVGltZXN0YW1wOmDqQV0KImFwaS5hbm5lYWxpbmcuZGV2L0lkZW50aXR5UHJvdmlkZXISN3Byb2plY3RzL3twcm9qZWN0fS9pZGVudGl0eVByb3ZpZGVycy97aWRlbnRpdHlQcm92aWRlcn0iXwoET2lkYxISCgppc3N1ZXJfdXJpGAEgASgJEjUKC2FjY2Vzc190eXBlGAIgASgOMiAuYW5uZWFsaW5nLmlhbS52MS5PaWRjQWNjZXNzVHlwZRIMCgRqd2tzGAMgASgJIrkCCgtSb2xlQmluZGluZxIMCgRuYW1lGAEgASgJEjEKBXN0YXRlGAIgASgOMiIuYW5uZWFsaW5nLmlhbS52MS5Sb2xlQmluZGluZ1N0YXRlEg4KBm1lbWJlchgDIAEoCRIkCgRyb2xlGAQgASgOMhYuYW5uZWFsaW5nLmlhbS52MS5Sb2xlEi8KC2NyZWF0ZV90aW1lGAUgASgLMhouZ29vZ2xlLnByb3RvYnVmLlRpbWVzdGFtcBIvCgt1cGRhdGVfdGltZRgGIAEoCzIaLmdvb2dsZS5wcm90b2J1Zi5UaW1lc3RhbXA6UepBTgodYXBpLmFubmVhbGluZy5kZXYvUm9sZUJpbmRpbmcSLXByb2plY3RzL3twcm9qZWN0fS9yb2xlQmluZGluZ3Mve3JvbGVCaW5kaW5nfSK1AgoOU2VydmljZUFjY291bnQSDAoEbmFtZRgBIAEoCRI0CgVzdGF0ZRgCIAEoDjIlLmFubmVhbGluZy5pYW0udjEuU2VydmljZUFjY291bnRTdGF0ZRITCgtkZXNjcmlwdGlvbhgDIAEoCRIMCgRqd2tzGAQgASgJEi8KC2NyZWF0ZV90aW1lGAUgASgLMhouZ29vZ2xlLnByb3RvYnVmLlRpbWVzdGFtcBIvCgt1cGRhdGVfdGltZRgGIAEoCzIaLmdvb2dsZS5wcm90b2J1Zi5UaW1lc3RhbXA6WupBVwogYXBpLmFubmVhbGluZy5kZXYvU2VydmljZUFjY291bnQSM3Byb2plY3RzL3twcm9qZWN0fS9zZXJ2aWNlQWNjb3VudHMve3NlcnZpY2VBY2NvdW50fUK+AQoUY29tLmFubmVhbGluZy5pYW0udjFCDlJlc291cmNlc1Byb3RvUAFaNGdpdGxhYi5jb20vZ3Jvc3NrdXIvYW5uZWFsaW5nL3Byb3RvL2FubmVhbGluZy9pYW0vdjGiAgNBSViqAhBBbm5lYWxpbmcuSWFtLlYxygIQQW5uZWFsaW5nXElhbVxWMeICHEFubmVhbGluZ1xJYW1cVjFcR1BCTWV0YWRhdGHqAhJBbm5lYWxpbmc6OklhbTo6VjFiBnByb3RvMw", [file_google_api_resource, file_google_protobuf_timestamp, file_annealing_iam_v1_enums]);

/**
 * @generated from message annealing.iam.v1.IdentityProvider
 */
export type IdentityProvider = Message<"annealing.iam.v1.IdentityProvider"> & {
  /**
   * @generated from field: string name = 1;
   */
  name: string;

  /**
   * @generated from field: annealing.iam.v1.IdentityProviderState state = 2;
   */
  state: IdentityProviderState;

  /**
   * @generated from field: annealing.iam.v1.Oidc oidc = 3;
   */
  oidc?: Oidc;

  /**
   * @generated from field: google.protobuf.Timestamp create_time = 4;
   */
  createTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp update_time = 5;
   */
  updateTime?: Timestamp;
};

/**
 * Describes the message annealing.iam.v1.IdentityProvider.
 * Use `create(IdentityProviderSchema)` to create a new message.
 */
export const IdentityProviderSchema: GenMessage<IdentityProvider> = /*@__PURE__*/
  messageDesc(file_annealing_iam_v1_resources, 0);

/**
 * @generated from message annealing.iam.v1.Oidc
 */
export type Oidc = Message<"annealing.iam.v1.Oidc"> & {
  /**
   * @generated from field: string issuer_uri = 1;
   */
  issuerUri: string;

  /**
   * @generated from field: annealing.iam.v1.OidcAccessType access_type = 2;
   */
  accessType: OidcAccessType;

  /**
   * @generated from field: string jwks = 3;
   */
  jwks: string;
};

/**
 * Describes the message annealing.iam.v1.Oidc.
 * Use `create(OidcSchema)` to create a new message.
 */
export const OidcSchema: GenMessage<Oidc> = /*@__PURE__*/
  messageDesc(file_annealing_iam_v1_resources, 1);

/**
 * @generated from message annealing.iam.v1.RoleBinding
 */
export type RoleBinding = Message<"annealing.iam.v1.RoleBinding"> & {
  /**
   * @generated from field: string name = 1;
   */
  name: string;

  /**
   * @generated from field: annealing.iam.v1.RoleBindingState state = 2;
   */
  state: RoleBindingState;

  /**
   * @generated from field: string member = 3;
   */
  member: string;

  /**
   * @generated from field: annealing.iam.v1.Role role = 4;
   */
  role: Role;

  /**
   * @generated from field: google.protobuf.Timestamp create_time = 5;
   */
  createTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp update_time = 6;
   */
  updateTime?: Timestamp;
};

/**
 * Describes the message annealing.iam.v1.RoleBinding.
 * Use `create(RoleBindingSchema)` to create a new message.
 */
export const RoleBindingSchema: GenMessage<RoleBinding> = /*@__PURE__*/
  messageDesc(file_annealing_iam_v1_resources, 2);

/**
 * @generated from message annealing.iam.v1.ServiceAccount
 */
export type ServiceAccount = Message<"annealing.iam.v1.ServiceAccount"> & {
  /**
   * @generated from field: string name = 1;
   */
  name: string;

  /**
   * @generated from field: annealing.iam.v1.ServiceAccountState state = 2;
   */
  state: ServiceAccountState;

  /**
   * @generated from field: string description = 3;
   */
  description: string;

  /**
   * @generated from field: string jwks = 4;
   */
  jwks: string;

  /**
   * @generated from field: google.protobuf.Timestamp create_time = 5;
   */
  createTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp update_time = 6;
   */
  updateTime?: Timestamp;
};

/**
 * Describes the message annealing.iam.v1.ServiceAccount.
 * Use `create(ServiceAccountSchema)` to create a new message.
 */
export const ServiceAccountSchema: GenMessage<ServiceAccount> = /*@__PURE__*/
  messageDesc(file_annealing_iam_v1_resources, 3);

