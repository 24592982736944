// @generated by protoc-gen-es v2.2.3 with parameter "target=ts"
// @generated from file annealing/compute/v1/enums.proto (package annealing.compute.v1, syntax proto3)
/* eslint-disable */

import type { GenEnum, GenFile } from "@bufbuild/protobuf/codegenv1";
import { enumDesc, fileDesc } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file annealing/compute/v1/enums.proto.
 */
export const file_annealing_compute_v1_enums: GenFile = /*@__PURE__*/
  fileDesc("CiBhbm5lYWxpbmcvY29tcHV0ZS92MS9lbnVtcy5wcm90bxIUYW5uZWFsaW5nLmNvbXB1dGUudjEq0QEKDE1hY2hpbmVTdGF0ZRIdChlNQUNISU5FX1NUQVRFX1VOU1BFQ0lGSUVEEAASGgoWTUFDSElORV9TVEFURV9DUkVBVElORxABEhoKFk1BQ0hJTkVfU1RBVEVfVVBEQVRJTkcQAhIaChZNQUNISU5FX1NUQVRFX0RFTEVUSU5HEAMSGAoUTUFDSElORV9TVEFURV9BQ1RJVkUQBBIYChRNQUNISU5FX1NUQVRFX0ZBSUxFRBAFEhoKFk1BQ0hJTkVfU1RBVEVfREVHUkFERUQQBirRAQoMQ2x1c3RlclN0YXRlEh0KGUNMVVNURVJfU1RBVEVfVU5TUEVDSUZJRUQQABIaChZDTFVTVEVSX1NUQVRFX0NSRUFUSU5HEAESGgoWQ0xVU1RFUl9TVEFURV9VUERBVElORxACEhoKFkNMVVNURVJfU1RBVEVfREVMRVRJTkcQAxIYChRDTFVTVEVSX1NUQVRFX0FDVElWRRAEEhgKFENMVVNURVJfU1RBVEVfRkFJTEVEEAUSGgoWQ0xVU1RFUl9TVEFURV9ERUdSQURFRBAGQtIBChhjb20uYW5uZWFsaW5nLmNvbXB1dGUudjFCCkVudW1zUHJvdG9QAVo4Z2l0bGFiLmNvbS9ncm9zc2t1ci9hbm5lYWxpbmcvcHJvdG8vYW5uZWFsaW5nL2NvbXB1dGUvdjGiAgNBQ1iqAhRBbm5lYWxpbmcuQ29tcHV0ZS5WMcoCFEFubmVhbGluZ1xDb21wdXRlXFYx4gIgQW5uZWFsaW5nXENvbXB1dGVcVjFcR1BCTWV0YWRhdGHqAhZBbm5lYWxpbmc6OkNvbXB1dGU6OlYxYgZwcm90bzM");

/**
 * @generated from enum annealing.compute.v1.MachineState
 */
export enum MachineState {
  /**
   * @generated from enum value: MACHINE_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: MACHINE_STATE_CREATING = 1;
   */
  CREATING = 1,

  /**
   * @generated from enum value: MACHINE_STATE_UPDATING = 2;
   */
  UPDATING = 2,

  /**
   * @generated from enum value: MACHINE_STATE_DELETING = 3;
   */
  DELETING = 3,

  /**
   * @generated from enum value: MACHINE_STATE_ACTIVE = 4;
   */
  ACTIVE = 4,

  /**
   * @generated from enum value: MACHINE_STATE_FAILED = 5;
   */
  FAILED = 5,

  /**
   * @generated from enum value: MACHINE_STATE_DEGRADED = 6;
   */
  DEGRADED = 6,
}

/**
 * Describes the enum annealing.compute.v1.MachineState.
 */
export const MachineStateSchema: GenEnum<MachineState> = /*@__PURE__*/
  enumDesc(file_annealing_compute_v1_enums, 0);

/**
 * @generated from enum annealing.compute.v1.ClusterState
 */
export enum ClusterState {
  /**
   * @generated from enum value: CLUSTER_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: CLUSTER_STATE_CREATING = 1;
   */
  CREATING = 1,

  /**
   * @generated from enum value: CLUSTER_STATE_UPDATING = 2;
   */
  UPDATING = 2,

  /**
   * @generated from enum value: CLUSTER_STATE_DELETING = 3;
   */
  DELETING = 3,

  /**
   * @generated from enum value: CLUSTER_STATE_ACTIVE = 4;
   */
  ACTIVE = 4,

  /**
   * @generated from enum value: CLUSTER_STATE_FAILED = 5;
   */
  FAILED = 5,

  /**
   * @generated from enum value: CLUSTER_STATE_DEGRADED = 6;
   */
  DEGRADED = 6,
}

/**
 * Describes the enum annealing.compute.v1.ClusterState.
 */
export const ClusterStateSchema: GenEnum<ClusterState> = /*@__PURE__*/
  enumDesc(file_annealing_compute_v1_enums, 1);

