import { timestampDate } from '@bufbuild/protobuf/wkt';
import TimeAgo from 'timeago-react';

import { appResourceName, formatIncarnation } from '../../helpers/util';
import { useListAssets } from '../../hooks/use_delivery_api';
import { routes } from '../../router';
import { PaginationFooter } from '../core/pagination_footer';
import { AssetHealthStateBadge } from './asset_health_state_badge';
import { AssetKebab } from './asset_kebab';
import { AssetSyncStateBadge } from './asset_sync_state_badge';

interface AssetTableProps {
  projectId: string;
  appId: string;
}

export function AssetTable(props: AssetTableProps) {
  const params = new URLSearchParams(window.location.search);
  const pageToken = params.get('pageToken');
  const parent = appResourceName(props.projectId, props.appId);
  const { data } = useListAssets({
    parent: parent,
    pageSize: 20,
    pageToken: pageToken === null ? '' : pageToken,
  });

  return (
    <div className="mt-4 flex flex-col">
      <div className="-my-2 overflow-x-auto py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="inline-block min-w-full overflow-hidden align-middle">
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Namespace
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Name
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Kind
                </th>
                <th className="whitespace-nowrap border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Health state
                </th>
                <th className="whitespace-nowrap border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Sync state
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Inc.
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Refreshed
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {data.assets.length == 0 && (
                <tr>
                  <td
                    colSpan={9}
                    className="whitespace-nowrap border-b border-gray-200 px-6 py-3 text-sm leading-5 text-gray-500"
                  >
                    No objects to display
                  </td>
                </tr>
              )}
              {data.assets.map((obj) => (
                <tr key={obj.name}>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm leading-5 text-gray-500">
                    {obj.name.split('/')[5].split('~')[3].length === 0
                      ? '\u2014'
                      : obj.name.split('/')[5].split('~')[3]}
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm font-medium leading-5 text-gray-900">
                    <a
                      {...routes.assetDetail({
                        projectId: props.projectId,
                        applicationId: obj.name.split('/')[3],
                        assetId: obj.name.split('/')[5],
                      }).link}
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      {obj.name.split('/')[5].split('~')[4]}
                    </a>
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm leading-5 text-gray-500">
                    {obj.name.split('/')[5].split('~')[2]}
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm leading-5 text-gray-500">
                    <AssetHealthStateBadge state={obj.healthState} />
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm leading-5 text-gray-500">
                    <AssetSyncStateBadge state={obj.syncState} />
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm font-medium leading-5 text-gray-900">
                    {obj.revision.split('/')[5] === '0' ?
                      '\u2014' :
                      (
                        <a
                          {...routes.incarnationDetail({
                            projectId: props.projectId,
                            applicationId: obj.revision.split('/')[3],
                            incarnationId: obj.revision.split('/')[5],
                          }).link}
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          {formatIncarnation(obj.revision.split('/')[5])}
                        </a>
                      )}
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
                    <TimeAgo
                      datetime={timestampDate(obj.refreshTime)}
                      opts={{ minInterval: 10 }}
                    />
                  </td>
                  <td className="w-full whitespace-nowrap border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
                    <AssetKebab
                      projectId={props.projectId}
                      appId={props.appId}
                      assetId={obj.name.split('/')[5]}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {data.assets.length > 0 && (
            <PaginationFooter
              totalSize={data.totalSize}
              pageSize={data.assets.length}
              startIndex={data.startIndex}
              previousPageLink={routes.assetList({
                projectId: props.projectId,
                applicationId: props.appId,
                pageToken: data.previousPageToken,
              }).link.href}
              nextPageLink={routes.assetList({
                projectId: props.projectId,
                applicationId: props.appId,
                pageToken: data.nextPageToken,
              }).link.href}
            />
          )}
        </div>
      </div>
    </div>
  );
}
