import { createClient } from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import useSWR from 'swr';

import { IamService } from '../../proto/annealing/iam/v1/service_pb';

const refreshInterval = 2000;
const transport = createGrpcWebTransport({ baseUrl: '' });

const client = createClient(IamService, transport);
const opts = { refreshInterval: refreshInterval, suspense: true };

async function listRoleBindings(req: any) {
  return await client.listRoleBindings(req);
}

async function getRoleBinding(req: any) {
  return await client.getRoleBinding(req);
}

export async function createRoleBinding(req: any) {
  return await client.createRoleBinding(req);
}

export async function deleteRoleBinding(req: any) {
  return await client.deleteRoleBinding(req);
}

async function listServiceAccounts(req: any) {
  return await client.listServiceAccounts(req);
}

async function getServiceAccount(req: any) {
  return await client.getServiceAccount(req);
}

export async function createServiceAccount(req: any) {
  return await client.createServiceAccount(req);
}

export async function deleteServiceAccount(req: any) {
  return await client.deleteServiceAccount(req);
}

async function listIdentityProviders(req: any) {
  return await client.listIdentityProviders(req);
}

async function getIdentityProvider(req: any) {
  return await client.getIdentityProvider(req);
}

export async function createIdentityProvider(req: any) {
  return await client.createIdentityProvider(req);
}

export async function deleteIdentityProvider(req: any) {
  return await client.deleteIdentityProvider(req);
}

export function useListRoleBindings(req: any) {
  return useSWR(
    ['annealing.iam.v1.IamService/ListRoleBindings', req],
    ([_m, req]) => listRoleBindings(req),
    opts,
  );
}

export function useGetRoleBinding(req: any) {
  return useSWR(
    ['annealing.iam.v1.IamService/GetRoleBinding', req],
    ([_m, req]) => getRoleBinding(req),
    opts,
  );
}

export function useListServiceAccounts(req: any) {
  return useSWR(
    ['annealing.iam.v1.IamService/ListServiceAccounts', req],
    ([_m, req]) => listServiceAccounts(req),
    opts,
  );
}

export function useGetServiceAccount(req: any) {
  return useSWR(
    ['annealing.iam.v1.IamService/GetServiceAccount', req],
    ([_m, req]) => getServiceAccount(req),
    opts,
  );
}

export function useListIdentityProviders(req: any) {
  return useSWR(
    ['annealing.iam.v1.IamService/ListIdentityProviders', req],
    ([_m, req]) => listIdentityProviders(req),
    opts,
  );
}

export function useGetIdentityProvider(req: any) {
  return useSWR(
    ['annealing.iam.v1.IamService/GetIdentityProvider', req],
    ([_m, req]) => getIdentityProvider(req),
    opts,
  );
}
