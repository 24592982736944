// @generated by protoc-gen-es v2.2.3 with parameter "target=ts"
// @generated from file annealing/sts/v1/service.proto (package annealing.sts.v1, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage, GenService } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import { file_google_api_annotations } from "../../../google/api/annotations_pb";
import type { SessionSchema, UserInfoSchema } from "./resources_pb";
import { file_annealing_sts_v1_resources } from "./resources_pb";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file annealing/sts/v1/service.proto.
 */
export const file_annealing_sts_v1_service: GenFile = /*@__PURE__*/
  fileDesc("Ch5hbm5lYWxpbmcvc3RzL3YxL3NlcnZpY2UucHJvdG8SEGFubmVhbGluZy5zdHMudjEifAoUQ3JlYXRlU2Vzc2lvblJlcXVlc3QSFgoOaWRlbnRpdHlfdG9rZW4YAiABKAkSGQoRaWRlbnRpdHlfcHJvdmlkZXIYAyABKAkSEgoKc2Vzc2lvbl9pZBgEIAEoCRIXCg9zZXJ2aWNlX2FjY291bnQYBSABKAlKBAgBEAIiFAoSR2V0VXNlckluZm9SZXF1ZXN0MvoBChRTZWN1cml0eVRva2VuU2VydmljZRJxCg1DcmVhdGVTZXNzaW9uEiYuYW5uZWFsaW5nLnN0cy52MS5DcmVhdGVTZXNzaW9uUmVxdWVzdBoZLmFubmVhbGluZy5zdHMudjEuU2Vzc2lvbiIdgtPkkwIXOgdzZXNzaW9uIgwvdjEvc2Vzc2lvbnMSbwoLR2V0VXNlckluZm8SJC5hbm5lYWxpbmcuc3RzLnYxLkdldFVzZXJJbmZvUmVxdWVzdBoaLmFubmVhbGluZy5zdHMudjEuVXNlckluZm8iHoLT5JMCGDoIdXNlckluZm8iDC92MS91c2VySW5mb0K8AQoUY29tLmFubmVhbGluZy5zdHMudjFCDFNlcnZpY2VQcm90b1ABWjRnaXRsYWIuY29tL2dyb3Nza3VyL2FubmVhbGluZy9wcm90by9hbm5lYWxpbmcvc3RzL3YxogIDQVNYqgIQQW5uZWFsaW5nLlN0cy5WMcoCEEFubmVhbGluZ1xTdHNcVjHiAhxBbm5lYWxpbmdcU3RzXFYxXEdQQk1ldGFkYXRh6gISQW5uZWFsaW5nOjpTdHM6OlYxYgZwcm90bzM", [file_google_api_annotations, file_annealing_sts_v1_resources]);

/**
 * @generated from message annealing.sts.v1.CreateSessionRequest
 */
export type CreateSessionRequest = Message<"annealing.sts.v1.CreateSessionRequest"> & {
  /**
   * The federated identity token to use.
   *
   * @generated from field: string identity_token = 2;
   */
  identityToken: string;

  /**
   * The identity provider to use.
   *
   * @generated from field: string identity_provider = 3;
   */
  identityProvider: string;

  /**
   * The session ID to use.
   *
   * @generated from field: string session_id = 4;
   */
  sessionId: string;

  /**
   * The service account to use.
   *
   * @generated from field: string service_account = 5;
   */
  serviceAccount: string;
};

/**
 * Describes the message annealing.sts.v1.CreateSessionRequest.
 * Use `create(CreateSessionRequestSchema)` to create a new message.
 */
export const CreateSessionRequestSchema: GenMessage<CreateSessionRequest> = /*@__PURE__*/
  messageDesc(file_annealing_sts_v1_service, 0);

/**
 * @generated from message annealing.sts.v1.GetUserInfoRequest
 */
export type GetUserInfoRequest = Message<"annealing.sts.v1.GetUserInfoRequest"> & {
};

/**
 * Describes the message annealing.sts.v1.GetUserInfoRequest.
 * Use `create(GetUserInfoRequestSchema)` to create a new message.
 */
export const GetUserInfoRequestSchema: GenMessage<GetUserInfoRequest> = /*@__PURE__*/
  messageDesc(file_annealing_sts_v1_service, 1);

/**
 * @generated from service annealing.sts.v1.SecurityTokenService
 */
export const SecurityTokenService: GenService<{
  /**
   * @generated from rpc annealing.sts.v1.SecurityTokenService.CreateSession
   */
  createSession: {
    methodKind: "unary";
    input: typeof CreateSessionRequestSchema;
    output: typeof SessionSchema;
  },
  /**
   * @generated from rpc annealing.sts.v1.SecurityTokenService.GetUserInfo
   */
  getUserInfo: {
    methodKind: "unary";
    input: typeof GetUserInfoRequestSchema;
    output: typeof UserInfoSchema;
  },
}> = /*@__PURE__*/
  serviceDesc(file_annealing_sts_v1_service, 0);

