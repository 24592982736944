// @generated by protoc-gen-es v2.2.3 with parameter "target=ts"
// @generated from file annealing/organization/v1/resources.proto (package annealing.organization.v1, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_google_api_resource } from "../../../google/api/resource_pb";
import type { Timestamp } from "@bufbuild/protobuf/wkt";
import { file_google_protobuf_timestamp } from "@bufbuild/protobuf/wkt";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file annealing/organization/v1/resources.proto.
 */
export const file_annealing_organization_v1_resources: GenFile = /*@__PURE__*/
  fileDesc("Cilhbm5lYWxpbmcvb3JnYW5pemF0aW9uL3YxL3Jlc291cmNlcy5wcm90bxIZYW5uZWFsaW5nLm9yZ2FuaXphdGlvbi52MSKJBAoHUHJvamVjdBIMCgRuYW1lGAEgASgJEhQKDGRpc3BsYXlfbmFtZRgCIAEoCRIVCg1jbHVzdGVyX2NvdW50GAMgASgFEhMKC2Fzc2V0X2NvdW50GAUgASgFEhEKCWFwcF9jb3VudBgGIAEoBRIVCg1yb2xsb3V0X2NvdW50GAcgASgFEh4KFmNoYXJ0X3JlcG9zaXRvcnlfY291bnQYCCABKAUSHAoUZ2l0X3JlcG9zaXRvcnlfY291bnQYCSABKAUSFQoNc3NoX2tleV9jb3VudBgKIAEoBRIcChRzc2hfa25vd25faG9zdF9jb3VudBgLIAEoBRIaChJyb2xlX2JpbmRpbmdfY291bnQYDCABKAUSHwoXaWRlbnRpdHlfcHJvdmlkZXJfY291bnQYDSABKAUSHQoVc2VydmljZV9hY2NvdW50X2NvdW50GA4gASgFEh8KF2lnbm9yZV9kaWZmZXJlbmNlX2NvdW50GA8gASgFEi8KC2NyZWF0ZV90aW1lGBAgASgLMhouZ29vZ2xlLnByb3RvYnVmLlRpbWVzdGFtcBIvCgt1cGRhdGVfdGltZRgRIAEoCzIaLmdvb2dsZS5wcm90b2J1Zi5UaW1lc3RhbXA6MupBLwoZYXBpLmFubmVhbGluZy5kZXYvUHJvamVjdBIScHJvamVjdHMve3Byb2plY3R9QvQBCh1jb20uYW5uZWFsaW5nLm9yZ2FuaXphdGlvbi52MUIOUmVzb3VyY2VzUHJvdG9QAVo9Z2l0bGFiLmNvbS9ncm9zc2t1ci9hbm5lYWxpbmcvcHJvdG8vYW5uZWFsaW5nL29yZ2FuaXphdGlvbi92MaICA0FPWKoCGUFubmVhbGluZy5Pcmdhbml6YXRpb24uVjHKAhlBbm5lYWxpbmdcT3JnYW5pemF0aW9uXFYx4gIlQW5uZWFsaW5nXE9yZ2FuaXphdGlvblxWMVxHUEJNZXRhZGF0YeoCG0FubmVhbGluZzo6T3JnYW5pemF0aW9uOjpWMWIGcHJvdG8z", [file_google_api_resource, file_google_protobuf_timestamp]);

/**
 * Project represents a permission boundary.
 *
 * @generated from message annealing.organization.v1.Project
 */
export type Project = Message<"annealing.organization.v1.Project"> & {
  /**
   * The resource name of the project.
   * Format: projects/{project}
   *
   * @generated from field: string name = 1;
   */
  name: string;

  /**
   * @generated from field: string display_name = 2;
   */
  displayName: string;

  /**
   * @generated from field: int32 cluster_count = 3;
   */
  clusterCount: number;

  /**
   * @generated from field: int32 asset_count = 5;
   */
  assetCount: number;

  /**
   * @generated from field: int32 app_count = 6;
   */
  appCount: number;

  /**
   * @generated from field: int32 rollout_count = 7;
   */
  rolloutCount: number;

  /**
   * @generated from field: int32 chart_repository_count = 8;
   */
  chartRepositoryCount: number;

  /**
   * @generated from field: int32 git_repository_count = 9;
   */
  gitRepositoryCount: number;

  /**
   * @generated from field: int32 ssh_key_count = 10;
   */
  sshKeyCount: number;

  /**
   * @generated from field: int32 ssh_known_host_count = 11;
   */
  sshKnownHostCount: number;

  /**
   * @generated from field: int32 role_binding_count = 12;
   */
  roleBindingCount: number;

  /**
   * @generated from field: int32 identity_provider_count = 13;
   */
  identityProviderCount: number;

  /**
   * @generated from field: int32 service_account_count = 14;
   */
  serviceAccountCount: number;

  /**
   * @generated from field: int32 ignore_difference_count = 15;
   */
  ignoreDifferenceCount: number;

  /**
   * @generated from field: google.protobuf.Timestamp create_time = 16;
   */
  createTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp update_time = 17;
   */
  updateTime?: Timestamp;
};

/**
 * Describes the message annealing.organization.v1.Project.
 * Use `create(ProjectSchema)` to create a new message.
 */
export const ProjectSchema: GenMessage<Project> = /*@__PURE__*/
  messageDesc(file_annealing_organization_v1_resources, 0);

