// @generated by protoc-gen-es v2.2.3 with parameter "target=ts"
// @generated from file annealing/sts/v1/resources.proto (package annealing.sts.v1, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc } from "@bufbuild/protobuf/codegenv1";
import { file_google_api_resource } from "../../../google/api/resource_pb";
import type { Timestamp } from "@bufbuild/protobuf/wkt";
import { file_google_protobuf_timestamp } from "@bufbuild/protobuf/wkt";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file annealing/sts/v1/resources.proto.
 */
export const file_annealing_sts_v1_resources: GenFile = /*@__PURE__*/
  fileDesc("CiBhbm5lYWxpbmcvc3RzL3YxL3Jlc291cmNlcy5wcm90bxIQYW5uZWFsaW5nLnN0cy52MSLtAQoHU2Vzc2lvbhIMCgRuYW1lGAEgASgJEg0KBXRva2VuGAIgASgJEi8KC2NyZWF0ZV90aW1lGAMgASgLMhouZ29vZ2xlLnByb3RvYnVmLlRpbWVzdGFtcBIvCgt1cGRhdGVfdGltZRgEIAEoCzIaLmdvb2dsZS5wcm90b2J1Zi5UaW1lc3RhbXASLwoLZXhwaXJlX3RpbWUYBSABKAsyGi5nb29nbGUucHJvdG9idWYuVGltZXN0YW1wOjLqQS8KGWFwaS5hbm5lYWxpbmcuZGV2L1Nlc3Npb24SEnNlc3Npb25zL3tzZXNzaW9ufSJuCghVc2VySW5mbxINCgVlbWFpbBgBIAEoCRIMCgRuYW1lGAIgASgJEg8KB3BpY3R1cmUYAyABKAk6NOpBMQoaYXBpLmFubmVhbGluZy5kZXYvVXNlckluZm8SE3VzZXJJbmZvL3t1c2VySW5mb31CvgEKFGNvbS5hbm5lYWxpbmcuc3RzLnYxQg5SZXNvdXJjZXNQcm90b1ABWjRnaXRsYWIuY29tL2dyb3Nza3VyL2FubmVhbGluZy9wcm90by9hbm5lYWxpbmcvc3RzL3YxogIDQVNYqgIQQW5uZWFsaW5nLlN0cy5WMcoCEEFubmVhbGluZ1xTdHNcVjHiAhxBbm5lYWxpbmdcU3RzXFYxXEdQQk1ldGFkYXRh6gISQW5uZWFsaW5nOjpTdHM6OlYxYgZwcm90bzM", [file_google_api_resource, file_google_protobuf_timestamp]);

/**
 * @generated from message annealing.sts.v1.Session
 */
export type Session = Message<"annealing.sts.v1.Session"> & {
  /**
   * The name of the session.
   *
   * @generated from field: string name = 1;
   */
  name: string;

  /**
   * Output only. The token for the session.
   *
   * @generated from field: string token = 2;
   */
  token: string;

  /**
   * Output only. The time the session was created.
   *
   * @generated from field: google.protobuf.Timestamp create_time = 3;
   */
  createTime?: Timestamp;

  /**
   * Output only. The time the session was updated.
   *
   * @generated from field: google.protobuf.Timestamp update_time = 4;
   */
  updateTime?: Timestamp;

  /**
   * Output only. The time the session expired.
   *
   * @generated from field: google.protobuf.Timestamp expire_time = 5;
   */
  expireTime?: Timestamp;
};

/**
 * Describes the message annealing.sts.v1.Session.
 * Use `create(SessionSchema)` to create a new message.
 */
export const SessionSchema: GenMessage<Session> = /*@__PURE__*/
  messageDesc(file_annealing_sts_v1_resources, 0);

/**
 * @generated from message annealing.sts.v1.UserInfo
 */
export type UserInfo = Message<"annealing.sts.v1.UserInfo"> & {
  /**
   * The email address of the user or service account.
   *
   * @generated from field: string email = 1;
   */
  email: string;

  /**
   * The name of the user.
   *
   * @generated from field: string name = 2;
   */
  name: string;

  /**
   * The avatar of the user.
   *
   * @generated from field: string picture = 3;
   */
  picture: string;
};

/**
 * Describes the message annealing.sts.v1.UserInfo.
 * Use `create(UserInfoSchema)` to create a new message.
 */
export const UserInfoSchema: GenMessage<UserInfo> = /*@__PURE__*/
  messageDesc(file_annealing_sts_v1_resources, 1);

