// @generated by protoc-gen-es v2.2.3 with parameter "target=ts"
// @generated from file annealing/organization/v1/service.proto (package annealing.organization.v1, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage, GenService } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import { file_google_api_annotations } from "../../../google/api/annotations_pb";
import { file_google_api_field_behavior } from "../../../google/api/field_behavior_pb";
import { file_google_api_resource } from "../../../google/api/resource_pb";
import type { EmptySchema } from "@bufbuild/protobuf/wkt";
import { file_google_protobuf_empty } from "@bufbuild/protobuf/wkt";
import type { Project, ProjectSchema } from "./resources_pb";
import { file_annealing_organization_v1_resources } from "./resources_pb";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file annealing/organization/v1/service.proto.
 */
export const file_annealing_organization_v1_service: GenFile = /*@__PURE__*/
  fileDesc("Cidhbm5lYWxpbmcvb3JnYW5pemF0aW9uL3YxL3NlcnZpY2UucHJvdG8SGWFubmVhbGluZy5vcmdhbml6YXRpb24udjEiUQoUQ3JlYXRlUHJvamVjdFJlcXVlc3QSOQoHcHJvamVjdBgBIAEoCzIiLmFubmVhbGluZy5vcmdhbml6YXRpb24udjEuUHJvamVjdEIE4kEBAiJBChFHZXRQcm9qZWN0UmVxdWVzdBIsCgRuYW1lGAEgASgJQh76QRsKGWFwaS5hbm5lYWxpbmcuZGV2L1Byb2plY3QiPAoTTGlzdFByb2plY3RzUmVxdWVzdBIRCglwYWdlX3NpemUYASABKAUSEgoKcGFnZV90b2tlbhgCIAEoCSKrAQoUTGlzdFByb2plY3RzUmVzcG9uc2USNAoIcHJvamVjdHMYASADKAsyIi5hbm5lYWxpbmcub3JnYW5pemF0aW9uLnYxLlByb2plY3QSFwoPbmV4dF9wYWdlX3Rva2VuGAIgASgJEhsKE3ByZXZpb3VzX3BhZ2VfdG9rZW4YAyABKAkSEgoKdG90YWxfc2l6ZRgEIAEoBRITCgtzdGFydF9pbmRleBgFIAEoBSJEChREZWxldGVQcm9qZWN0UmVxdWVzdBIsCgRuYW1lGAEgASgJQh76QRsKGWFwaS5hbm5lYWxpbmcuZGV2L1Byb2plY3QymwQKE09yZ2FuaXphdGlvblNlcnZpY2USfQoKR2V0UHJvamVjdBIsLmFubmVhbGluZy5vcmdhbml6YXRpb24udjEuR2V0UHJvamVjdFJlcXVlc3QaIi5hbm5lYWxpbmcub3JnYW5pemF0aW9uLnYxLlByb2plY3QiHYLT5JMCFxIVL3YxL3tuYW1lPXByb2plY3RzLyp9EoUBCgxMaXN0UHJvamVjdHMSLi5hbm5lYWxpbmcub3JnYW5pemF0aW9uLnYxLkxpc3RQcm9qZWN0c1JlcXVlc3QaLy5hbm5lYWxpbmcub3JnYW5pemF0aW9uLnYxLkxpc3RQcm9qZWN0c1Jlc3BvbnNlIhSC0+STAg4SDC92MS9wcm9qZWN0cxKDAQoNQ3JlYXRlUHJvamVjdBIvLmFubmVhbGluZy5vcmdhbml6YXRpb24udjEuQ3JlYXRlUHJvamVjdFJlcXVlc3QaIi5hbm5lYWxpbmcub3JnYW5pemF0aW9uLnYxLlByb2plY3QiHYLT5JMCFzoHcHJvamVjdCIML3YxL3Byb2plY3RzEncKDURlbGV0ZVByb2plY3QSLy5hbm5lYWxpbmcub3JnYW5pemF0aW9uLnYxLkRlbGV0ZVByb2plY3RSZXF1ZXN0GhYuZ29vZ2xlLnByb3RvYnVmLkVtcHR5Ih2C0+STAhcqFS92MS97bmFtZT1wcm9qZWN0cy8qfULyAQodY29tLmFubmVhbGluZy5vcmdhbml6YXRpb24udjFCDFNlcnZpY2VQcm90b1ABWj1naXRsYWIuY29tL2dyb3Nza3VyL2FubmVhbGluZy9wcm90by9hbm5lYWxpbmcvb3JnYW5pemF0aW9uL3YxogIDQU9YqgIZQW5uZWFsaW5nLk9yZ2FuaXphdGlvbi5WMcoCGUFubmVhbGluZ1xPcmdhbml6YXRpb25cVjHiAiVBbm5lYWxpbmdcT3JnYW5pemF0aW9uXFYxXEdQQk1ldGFkYXRh6gIbQW5uZWFsaW5nOjpPcmdhbml6YXRpb246OlYxYgZwcm90bzM", [file_google_api_annotations, file_google_api_field_behavior, file_google_api_resource, file_google_protobuf_empty, file_annealing_organization_v1_resources]);

/**
 * @generated from message annealing.organization.v1.CreateProjectRequest
 */
export type CreateProjectRequest = Message<"annealing.organization.v1.CreateProjectRequest"> & {
  /**
   * @generated from field: annealing.organization.v1.Project project = 1;
   */
  project?: Project;
};

/**
 * Describes the message annealing.organization.v1.CreateProjectRequest.
 * Use `create(CreateProjectRequestSchema)` to create a new message.
 */
export const CreateProjectRequestSchema: GenMessage<CreateProjectRequest> = /*@__PURE__*/
  messageDesc(file_annealing_organization_v1_service, 0);

/**
 * @generated from message annealing.organization.v1.GetProjectRequest
 */
export type GetProjectRequest = Message<"annealing.organization.v1.GetProjectRequest"> & {
  /**
   * @generated from field: string name = 1;
   */
  name: string;
};

/**
 * Describes the message annealing.organization.v1.GetProjectRequest.
 * Use `create(GetProjectRequestSchema)` to create a new message.
 */
export const GetProjectRequestSchema: GenMessage<GetProjectRequest> = /*@__PURE__*/
  messageDesc(file_annealing_organization_v1_service, 1);

/**
 * @generated from message annealing.organization.v1.ListProjectsRequest
 */
export type ListProjectsRequest = Message<"annealing.organization.v1.ListProjectsRequest"> & {
  /**
   * @generated from field: int32 page_size = 1;
   */
  pageSize: number;

  /**
   * @generated from field: string page_token = 2;
   */
  pageToken: string;
};

/**
 * Describes the message annealing.organization.v1.ListProjectsRequest.
 * Use `create(ListProjectsRequestSchema)` to create a new message.
 */
export const ListProjectsRequestSchema: GenMessage<ListProjectsRequest> = /*@__PURE__*/
  messageDesc(file_annealing_organization_v1_service, 2);

/**
 * @generated from message annealing.organization.v1.ListProjectsResponse
 */
export type ListProjectsResponse = Message<"annealing.organization.v1.ListProjectsResponse"> & {
  /**
   * @generated from field: repeated annealing.organization.v1.Project projects = 1;
   */
  projects: Project[];

  /**
   * @generated from field: string next_page_token = 2;
   */
  nextPageToken: string;

  /**
   * @generated from field: string previous_page_token = 3;
   */
  previousPageToken: string;

  /**
   * @generated from field: int32 total_size = 4;
   */
  totalSize: number;

  /**
   * @generated from field: int32 start_index = 5;
   */
  startIndex: number;
};

/**
 * Describes the message annealing.organization.v1.ListProjectsResponse.
 * Use `create(ListProjectsResponseSchema)` to create a new message.
 */
export const ListProjectsResponseSchema: GenMessage<ListProjectsResponse> = /*@__PURE__*/
  messageDesc(file_annealing_organization_v1_service, 3);

/**
 * @generated from message annealing.organization.v1.DeleteProjectRequest
 */
export type DeleteProjectRequest = Message<"annealing.organization.v1.DeleteProjectRequest"> & {
  /**
   * @generated from field: string name = 1;
   */
  name: string;
};

/**
 * Describes the message annealing.organization.v1.DeleteProjectRequest.
 * Use `create(DeleteProjectRequestSchema)` to create a new message.
 */
export const DeleteProjectRequestSchema: GenMessage<DeleteProjectRequest> = /*@__PURE__*/
  messageDesc(file_annealing_organization_v1_service, 4);

/**
 * @generated from service annealing.organization.v1.OrganizationService
 */
export const OrganizationService: GenService<{
  /**
   * @generated from rpc annealing.organization.v1.OrganizationService.GetProject
   */
  getProject: {
    methodKind: "unary";
    input: typeof GetProjectRequestSchema;
    output: typeof ProjectSchema;
  },
  /**
   * @generated from rpc annealing.organization.v1.OrganizationService.ListProjects
   */
  listProjects: {
    methodKind: "unary";
    input: typeof ListProjectsRequestSchema;
    output: typeof ListProjectsResponseSchema;
  },
  /**
   * @generated from rpc annealing.organization.v1.OrganizationService.CreateProject
   */
  createProject: {
    methodKind: "unary";
    input: typeof CreateProjectRequestSchema;
    output: typeof ProjectSchema;
  },
  /**
   * @generated from rpc annealing.organization.v1.OrganizationService.DeleteProject
   */
  deleteProject: {
    methodKind: "unary";
    input: typeof DeleteProjectRequestSchema;
    output: typeof EmptySchema;
  },
}> = /*@__PURE__*/
  serviceDesc(file_annealing_organization_v1_service, 0);

