import { timestampDate } from '@bufbuild/protobuf/wkt';

import { formatDate, sshKnownHostResourceName } from '../../helpers/util';
import { useGetSSHKnownHost } from '../../hooks/use_delivery_api';
import { SSHKnownHostStateBadge } from './ssh_known_host_state_badge';

interface SSHKnownHostInfoProps {
  projectId: string;
  hostId: string;
}

export function SSHKnownHostInfo(props: SSHKnownHostInfoProps) {
  const name = sshKnownHostResourceName(props.projectId, props.hostId);
  const { data } = useGetSSHKnownHost({ name: name });
  const host = data;

  return (
    <>
      <div className="mt-4 max-w-3xl overflow-hidden border-t border-b border-gray-200">
        <div className="px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">State</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <SSHKnownHostStateBadge state={host.state} />
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Hostname</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {host.hostname}
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Algorithm</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {host.algorithm}
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Fingerprint</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {host.fingerprintSha256}
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Created</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {formatDate(timestampDate(host.createTime))}
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Updated</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {formatDate(timestampDate(host.updateTime))}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
}
