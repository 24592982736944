import { timestampDate } from '@bufbuild/protobuf/wkt';

import {
  formatDate,
  roleBindingResourceName,
  roleToString,
} from '../../helpers/util';
import { useGetRoleBinding } from '../../hooks/use_iam_api';
import { RoleBindingStateBadge } from './role_binding_state_badge';

interface RoleBindingInfoProps {
  projectId: string;
  roleBindingId: string;
}

export function RoleBindingInfo(props: RoleBindingInfoProps) {
  const name = roleBindingResourceName(props.projectId, props.roleBindingId);
  const { data } = useGetRoleBinding({ name: name });
  const binding = data;

  return (
    <div className="mt-4 max-w-5xl overflow-hidden border-t border-b border-gray-200">
      <div className="px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">State</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <RoleBindingStateBadge state={binding.state} />
            </dd>
          </div>
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Member</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {binding.member}
            </dd>
          </div>
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Role</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <span className="inline-flex items-center rounded bg-gray-100 px-2 py-0.5 text-xs font-medium leading-4 text-gray-800">
                {roleToString(binding.role)}
              </span>
            </dd>
          </div>
          <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Created</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              {formatDate(timestampDate(binding.createTime))}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}
