import { timestampDate } from '@bufbuild/protobuf/wkt';

import {
  formatDate,
  formatIncarnation,
  objectResourceName,
} from '../../helpers/util';
import { useGetAsset } from '../../hooks/use_delivery_api';
import { routes } from '../../router';
import { AssetHealthStateBadge } from './asset_health_state_badge';
import { AssetSyncStateBadge } from './asset_sync_state_badge';

interface AssetInfoProps {
  projectId: string;
  appId: string;
  clusterId: string;
  apiGroup: string;
  apiKind: string;
  namespaceName: string;
  objectName: string;
}

export function AssetInfo(props: AssetInfoProps) {
  const name = objectResourceName(
    props.projectId,
    props.appId,
    props.clusterId,
    props.apiGroup,
    props.apiKind,
    props.namespaceName,
    props.objectName,
  );
  const { data } = useGetAsset({ name: name });
  const asset = data;

  return (
    <div>
      <div className="px-6 pt-8">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Asset basics
        </h3>
      </div>
      <div className="mt-3 max-w-xl overflow-hidden border-t border-b border-gray-200">
        <div className="px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Sync state</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <AssetSyncStateBadge state={asset.syncState} />
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Health state
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <AssetHealthStateBadge state={asset.healthState} />
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Namespace</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {props.namespaceName.length === 0
                  ? '\u2014'
                  : props.namespaceName}
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">API Group</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {props.apiGroup.length === 0 ? '\u2014' : props.apiGroup}
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">API Kind</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {props.apiKind}
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Incarnation</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <a
                  {...routes.incarnationDetail({
                    projectId: props.projectId,
                    applicationId: props.appId,
                    incarnationId: asset.revision.split('/')[5],
                  }).link}
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  {formatIncarnation(asset.revision.split('/')[5])}
                </a>
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Cluster</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <a
                  {...routes.clusterDetail({
                    projectId: props.projectId,
                    clusterId: asset.name.split('/')[5],
                  }).link}
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                >
                  {asset.name.split('/')[5].split('~')[0]}
                </a>
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Updated</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {formatDate(timestampDate(asset.updateTime))}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}
