import { create } from '@bufbuild/protobuf';
import { timestampDate } from '@bufbuild/protobuf/wkt';

import { OperationMetadataSchema } from '../../../proto/annealing/compute/v1/resources_pb';
import { formatDate } from '../../helpers/util';
import { useListOperations } from '../../hooks/use_operations_api';
import { routes } from '../../router';
import { OperationStateBadge } from './operation_state_badge';

interface OperationTableProps {
  projectId: string;
}

function operationState(operation: any) {
  if (operation.done) {
    if (operation.error) {
      return 'Failed';
    }
    return 'Succeeded';
  }
  return 'Running';
}

function renderOperationRow(operation: any) {
  let opMeta = create(OperationMetadataSchema, {});
  operation.metadata.unpackTo(opMeta);

  return (
    <tr key={operation.name}>
      <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-left text-sm font-medium leading-5 text-gray-900">
        <a
          {...routes.computeOperationDetail({
            projectId: operation.name.split('/')[1],
            operationId: operation.name.split('/')[3],
          }).link}
          className="font-medium text-indigo-600 hover:text-indigo-500">
          {operation.name.split('/')[3].split('-')[0]}
        </a>
      </td>
      <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
        <OperationStateBadge state={operationState(operation)} />
      </td>
      <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
        {opMeta.verb}
      </td>
      <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
        {opMeta.target}
      </td>
      <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
        {formatDate(timestampDate(opMeta.createTime))}
      </td>
      <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
        {opMeta.endTime ? formatDate(timestampDate(opMeta.endTime)) : '\u2014'}
      </td>
      <td className="w-full whitespace-nowrap border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
      </td>
    </tr>
  );
}

export function OperationTable(props: OperationTableProps) {
  const { data } = useListOperations({
    name: `projects/${props.projectId}`,
    pageSize: 15,
    pageToken: '',
  });

  return (
    <div className="mt-4 flex flex-col">
      <div className="-my-2 overflow-x-auto py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="inline-block min-w-full overflow-hidden align-middle">
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Name
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  State
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Verb
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Target
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Created
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Ended
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {data.operations.length == 0 && (
                <tr>
                  <td
                    colSpan={7}
                    className="whitespace-nowrap border-b border-gray-200 px-6 py-3 text-sm leading-5 text-gray-500"
                  >
                    No operations to display
                  </td>
                </tr>
              )}
              {data.operations.map((operation) =>
                renderOperationRow(operation)
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
