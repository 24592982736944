import { timestampDate } from '@bufbuild/protobuf/wkt';

import {
  formatDate,
  rolloutResourceName,
  rolloutStepDuration,
  rolloutStepKindToString,
} from '../../helpers/util';
import { useListRolloutSteps } from '../../hooks/use_delivery_api';
import { useNow } from '../../hooks/use_now';
import { routes } from '../../router';
import { PaginationFooter } from '../core/pagination_footer';
import { RolloutStepStateBadge } from './rollout_step_state_badge';

interface RolloutStepTableProps {
  projectId: string;
  appId: string;
  rolloutId: string;
}

export function RolloutStepTable(props: RolloutStepTableProps) {
  useNow();
  const parent = rolloutResourceName(
    props.projectId,
    props.appId,
    props.rolloutId,
  );
  const { data } = useListRolloutSteps({
    parent: parent,
    pageSize: 15,
    pageToken: '',
  });

  return (
    <div className="mt-3 flex flex-col">
      <div className="-my-2 overflow-x-auto py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="inline-block min-w-full overflow-hidden align-middle">
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  ID
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  State
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Kind
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-right text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Assets
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-right text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Duration
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Created
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {data.rolloutSteps.length == 0 && (
                <tr>
                  <td
                    colSpan={6}
                    className="whitespace-nowrap border-b border-gray-200 px-6 py-3 text-sm leading-5 text-gray-500"
                  >
                    No rollout steps to display
                  </td>
                </tr>
              )}
              {data.rolloutSteps.map((step) => (
                <tr key={step.name}>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-3 text-sm leading-5 text-gray-500">
                    {step.name.split('/')[7]}
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-3 text-sm leading-5 text-gray-500">
                    <RolloutStepStateBadge state={step.state} showText={true} />
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-3 text-sm leading-5 text-gray-500">
                    <span className="inline-flex items-center rounded bg-gray-100 px-2 py-0.5 text-xs font-medium leading-4 text-gray-800">
                      {rolloutStepKindToString(step.kind)}
                    </span>
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-3 text-right text-sm leading-5 text-gray-500">
                    {step.assetCount === 0 ? '\u2014' : step.assetCount}
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-3 text-right text-sm leading-5 text-gray-500">
                    {rolloutStepDuration(step)}
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-3 text-left text-sm leading-5 text-gray-500">
                    {formatDate(timestampDate(step.createTime))}
                  </td>
                  <td className="w-full whitespace-nowrap border-b border-gray-200 px-6 py-3 text-left text-sm leading-5 text-gray-500">
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {data.rolloutSteps.length > 0 && (
            <PaginationFooter
              totalSize={data.totalSize}
              pageSize={data.rolloutSteps.length}
              startIndex={data.startIndex}
              previousPageLink={routes.rolloutStepList({
                projectId: props.projectId,
                applicationId: props.appId,
                rolloutId: props.rolloutId,
                pageToken: data.previousPageToken,
              }).link.href}
              nextPageLink={routes.rolloutStepList({
                projectId: props.projectId,
                applicationId: props.appId,
                rolloutId: props.rolloutId,
                pageToken: data.nextPageToken,
              }).link.href}
            />
          )}
        </div>
      </div>
    </div>
  );
}
