import { timestampDate } from '@bufbuild/protobuf/wkt';

import {
  formatDate,
  formatIncarnation,
  rolloutDuration,
  rolloutResourceName,
} from '../../helpers/util';
import { useGetRollout } from '../../hooks/use_delivery_api';
import { useNow } from '../../hooks/use_now';
import { routes } from '../../router';
import { RolloutProgressBar } from './rollout_progress_bar';
import { RolloutStateBadge } from './rollout_state_badge';

interface RolloutInfoProps {
  projectId: string;
  appId: string;
  rolloutId: string;
}

export function RolloutInfo(props: RolloutInfoProps) {
  useNow();
  const name = rolloutResourceName(
    props.projectId,
    props.appId,
    props.rolloutId,
  );
  const { data } = useGetRollout({ name: name });
  const rollout = data;

  return (
    <div className="mt-4 mb-6">
      <div className="max-w-xl overflow-hidden border-t border-b border-gray-200">
        <div className="px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">State</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <RolloutStateBadge state={rollout.state} />
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Incarnation</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <a
                  {...routes.incarnationDetail({
                    projectId: props.projectId,
                    applicationId: props.appId,
                    incarnationId: rollout.incarnation.split('/')[5],
                  }).link}
                  className="font-medium text-indigo-600 hover:text-indigo-500"
                  href={`/${rollout.incarnation}`}
                >
                  {formatIncarnation(rollout.incarnation.split('/')[5])}
                </a>
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Assets</dt>
              <dd className="mt-1 text-sm text-gray-500 sm:col-span-2 sm:mt-0">
                {rollout.assetCount}
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Progress</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <RolloutProgressBar rollout={rollout} />
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Duration</dt>
              <dd className="mt-1 text-sm text-gray-500 sm:col-span-2 sm:mt-0">
                {rolloutDuration(rollout)}
              </dd>
            </div>
            <div className="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-2 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Started</dt>
              <dd className="mt-1 text-sm text-gray-500 sm:col-span-2 sm:mt-0">
                {formatDate(timestampDate(rollout.createTime))}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}
