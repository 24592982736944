// @generated by protoc-gen-es v2.2.3 with parameter "target=ts"
// @generated from file annealing/iam/v1/enums.proto (package annealing.iam.v1, syntax proto3)
/* eslint-disable */

import type { GenEnum, GenFile } from "@bufbuild/protobuf/codegenv1";
import { enumDesc, fileDesc } from "@bufbuild/protobuf/codegenv1";

/**
 * Describes the file annealing/iam/v1/enums.proto.
 */
export const file_annealing_iam_v1_enums: GenFile = /*@__PURE__*/
  fileDesc("Chxhbm5lYWxpbmcvaWFtL3YxL2VudW1zLnByb3RvEhBhbm5lYWxpbmcuaWFtLnYxKqwBChVJZGVudGl0eVByb3ZpZGVyU3RhdGUSJwojSURFTlRJVFlfUFJPVklERVJfU1RBVEVfVU5TUEVDSUZJRUQQABIjCh9JREVOVElUWV9QUk9WSURFUl9TVEFURV9QRU5ESU5HEAESIgoeSURFTlRJVFlfUFJPVklERVJfU1RBVEVfQUNUSVZFEAISIQodSURFTlRJVFlfUFJPVklERVJfU1RBVEVfRVJST1IQAyptCg5PaWRjQWNjZXNzVHlwZRIgChxPSURDX0FDQ0VTU19UWVBFX1VOU1BFQ0lGSUVEEAASGwoXT0lEQ19BQ0NFU1NfVFlQRV9QVUJMSUMQARIcChhPSURDX0FDQ0VTU19UWVBFX1BSSVZBVEUQAipxCgRSb2xlEhQKEFJPTEVfVU5TUEVDSUZJRUQQABIOCgpST0xFX09XTkVSEAESDwoLUk9MRV9FRElUT1IQAhIPCgtST0xFX1ZJRVdFUhADEg4KClJPTEVfQUdFTlQQBBIRCg1ST0xFX0RFUExPWUVSEAUqdQoQUm9sZUJpbmRpbmdTdGF0ZRIiCh5ST0xFX0JJTkRJTkdfU1RBVEVfVU5TUEVDSUZJRUQQABIeChpST0xFX0JJTkRJTkdfU1RBVEVfUEVORElORxABEh0KGVJPTEVfQklORElOR19TVEFURV9BQ1RJVkUQAiqBAQoTU2VydmljZUFjY291bnRTdGF0ZRIlCiFTRVJWSUNFX0FDQ09VTlRfU1RBVEVfVU5TUEVDSUZJRUQQABIhCh1TRVJWSUNFX0FDQ09VTlRfU1RBVEVfUEVORElORxABEiAKHFNFUlZJQ0VfQUNDT1VOVF9TVEFURV9BQ1RJVkUQAiqQAQoWU2VydmljZUFjY291bnRLZXlTdGF0ZRIpCiVTRVJWSUNFX0FDQ09VTlRfS0VZX1NUQVRFX1VOU1BFQ0lGSUVEEAASJQohU0VSVklDRV9BQ0NPVU5UX0tFWV9TVEFURV9QRU5ESU5HEAESJAogU0VSVklDRV9BQ0NPVU5UX0tFWV9TVEFURV9BQ1RJVkUQAkK6AQoUY29tLmFubmVhbGluZy5pYW0udjFCCkVudW1zUHJvdG9QAVo0Z2l0bGFiLmNvbS9ncm9zc2t1ci9hbm5lYWxpbmcvcHJvdG8vYW5uZWFsaW5nL2lhbS92MaICA0FJWKoCEEFubmVhbGluZy5JYW0uVjHKAhBBbm5lYWxpbmdcSWFtXFYx4gIcQW5uZWFsaW5nXElhbVxWMVxHUEJNZXRhZGF0YeoCEkFubmVhbGluZzo6SWFtOjpWMWIGcHJvdG8z");

/**
 * @generated from enum annealing.iam.v1.IdentityProviderState
 */
export enum IdentityProviderState {
  /**
   * @generated from enum value: IDENTITY_PROVIDER_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: IDENTITY_PROVIDER_STATE_PENDING = 1;
   */
  PENDING = 1,

  /**
   * @generated from enum value: IDENTITY_PROVIDER_STATE_ACTIVE = 2;
   */
  ACTIVE = 2,

  /**
   * @generated from enum value: IDENTITY_PROVIDER_STATE_ERROR = 3;
   */
  ERROR = 3,
}

/**
 * Describes the enum annealing.iam.v1.IdentityProviderState.
 */
export const IdentityProviderStateSchema: GenEnum<IdentityProviderState> = /*@__PURE__*/
  enumDesc(file_annealing_iam_v1_enums, 0);

/**
 * @generated from enum annealing.iam.v1.OidcAccessType
 */
export enum OidcAccessType {
  /**
   * @generated from enum value: OIDC_ACCESS_TYPE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: OIDC_ACCESS_TYPE_PUBLIC = 1;
   */
  PUBLIC = 1,

  /**
   * @generated from enum value: OIDC_ACCESS_TYPE_PRIVATE = 2;
   */
  PRIVATE = 2,
}

/**
 * Describes the enum annealing.iam.v1.OidcAccessType.
 */
export const OidcAccessTypeSchema: GenEnum<OidcAccessType> = /*@__PURE__*/
  enumDesc(file_annealing_iam_v1_enums, 1);

/**
 * @generated from enum annealing.iam.v1.Role
 */
export enum Role {
  /**
   * @generated from enum value: ROLE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ROLE_OWNER = 1;
   */
  OWNER = 1,

  /**
   * @generated from enum value: ROLE_EDITOR = 2;
   */
  EDITOR = 2,

  /**
   * @generated from enum value: ROLE_VIEWER = 3;
   */
  VIEWER = 3,

  /**
   * @generated from enum value: ROLE_AGENT = 4;
   */
  AGENT = 4,

  /**
   * @generated from enum value: ROLE_DEPLOYER = 5;
   */
  DEPLOYER = 5,
}

/**
 * Describes the enum annealing.iam.v1.Role.
 */
export const RoleSchema: GenEnum<Role> = /*@__PURE__*/
  enumDesc(file_annealing_iam_v1_enums, 2);

/**
 * @generated from enum annealing.iam.v1.RoleBindingState
 */
export enum RoleBindingState {
  /**
   * @generated from enum value: ROLE_BINDING_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: ROLE_BINDING_STATE_PENDING = 1;
   */
  PENDING = 1,

  /**
   * @generated from enum value: ROLE_BINDING_STATE_ACTIVE = 2;
   */
  ACTIVE = 2,
}

/**
 * Describes the enum annealing.iam.v1.RoleBindingState.
 */
export const RoleBindingStateSchema: GenEnum<RoleBindingState> = /*@__PURE__*/
  enumDesc(file_annealing_iam_v1_enums, 3);

/**
 * @generated from enum annealing.iam.v1.ServiceAccountState
 */
export enum ServiceAccountState {
  /**
   * @generated from enum value: SERVICE_ACCOUNT_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: SERVICE_ACCOUNT_STATE_PENDING = 1;
   */
  PENDING = 1,

  /**
   * @generated from enum value: SERVICE_ACCOUNT_STATE_ACTIVE = 2;
   */
  ACTIVE = 2,
}

/**
 * Describes the enum annealing.iam.v1.ServiceAccountState.
 */
export const ServiceAccountStateSchema: GenEnum<ServiceAccountState> = /*@__PURE__*/
  enumDesc(file_annealing_iam_v1_enums, 4);

/**
 * @generated from enum annealing.iam.v1.ServiceAccountKeyState
 */
export enum ServiceAccountKeyState {
  /**
   * @generated from enum value: SERVICE_ACCOUNT_KEY_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: SERVICE_ACCOUNT_KEY_STATE_PENDING = 1;
   */
  PENDING = 1,

  /**
   * @generated from enum value: SERVICE_ACCOUNT_KEY_STATE_ACTIVE = 2;
   */
  ACTIVE = 2,
}

/**
 * Describes the enum annealing.iam.v1.ServiceAccountKeyState.
 */
export const ServiceAccountKeyStateSchema: GenEnum<ServiceAccountKeyState> = /*@__PURE__*/
  enumDesc(file_annealing_iam_v1_enums, 5);

