import { timestampDate } from '@bufbuild/protobuf/wkt';

import {
  formatDate,
  oidcAccessTypeToString,
  projectResourceName,
} from '../../helpers/util';
import { useListIdentityProviders } from '../../hooks/use_iam_api';
import { routes } from '../../router';
import { PaginationFooter } from '../core/pagination_footer';
import { OIDCProviderKebab } from './oidc_provider_kebab';
import { OIDCProviderStateBadge } from './oidc_provider_state_badge';

interface OIDCProviderTableProps {
  projectId: string;
}

export function OIDCProviderTable(props: OIDCProviderTableProps) {
  const parent = projectResourceName(props.projectId);
  const { data, mutate } = useListIdentityProviders({
    parent: parent,
    pageSize: 15,
    pageToken: '',
  });
  return (
    <div className="mt-4 flex flex-col">
      <div className="-my-2 overflow-x-auto py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="inline-block min-w-full overflow-hidden align-middle">
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                  Name
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                  State
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                  Issuer URI
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                  Discovery
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                  Created
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {data.identityProviders.length == 0 && (
                <tr>
                  <td
                    colSpan={6}
                    className="whitespace-nowrap border-b border-gray-200 px-6 py-3 text-sm leading-5 text-gray-500"
                  >
                    No identity providers to display
                  </td>
                </tr>
              )}
              {data.identityProviders.map((idp) => (
                <tr key={idp.name}>
                  <td className="py2 whitespace-nowrap border-b border-gray-200 px-6 text-sm font-medium leading-5 text-gray-900">
                    <a
                      {...routes.oidcProviderDetail({
                        projectId: props.projectId,
                        oidcProviderId: idp.name.split('/')[3],
                      }).link}
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      {idp.name.split('/')[3]}
                    </a>
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm leading-5 text-gray-500">
                    <OIDCProviderStateBadge state={idp.state} />
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
                    <span>{idp.oidc.issuerUri}</span>
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
                    <span className="inline-flex items-center rounded bg-gray-100 px-2 py-0.5 text-xs font-medium leading-4 text-gray-800">
                      {oidcAccessTypeToString(idp.oidc.accessType)}
                    </span>
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
                    {formatDate(timestampDate(idp.createTime))}
                  </td>
                  <td className="w-full border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
                    <OIDCProviderKebab
                      projectId={props.projectId}
                      providerId={idp.name.split('/')[3]}
                      mutate={mutate}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {data.identityProviders.length > 0 && (
            <PaginationFooter
              totalSize={data.totalSize}
              pageSize={data.identityProviders.length}
              startIndex={data.startIndex}
              previousPageLink={routes.oidcProviderList({
                projectId: props.projectId,
                pageToken: data.previousPageToken,
              }).link.href}
              nextPageLink={routes.oidcProviderList({
                projectId: props.projectId,
                pageToken: data.nextPageToken,
              }).link.href}
            />
          )}
        </div>
      </div>
    </div>
  );
}
