import { timestampDate } from '@bufbuild/protobuf/wkt';
import TimeAgo from 'timeago-react';

import { useListMachines } from '../../hooks/use_compute_api';
import { routes } from '../../router';
import { PaginationFooter } from '../core/pagination_footer';
import { MachineKebab } from './machine_kebab';
import { MachineStateBadge } from './machine_state_badge';

interface MachineTableProps {
  projectId: string;
}

export function MachineTable(props: MachineTableProps) {
  const { data, mutate } = useListMachines({
    parent: `projects/${props.projectId}`,
    pageSize: 15,
    pageToken: '',
  });
  console.log(data);

  return (
    <div className="mt-4 flex flex-col">
      <div className="-my-2 overflow-x-auto py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="inline-block min-w-full overflow-hidden align-middle">
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Name
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  State
                </th>
                <th className="whitespace-nowrap border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  OS name
                </th>
                <th className="whitespace-nowrap border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  OS version
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Arch
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-right text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  vCPUs
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-right text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Memory
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Created
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {data.machines.length == 0 && (
                <tr>
                  <td
                    colSpan={9}
                    className="whitespace-nowrap border-b border-gray-200 px-6 py-3 text-sm leading-5 text-gray-500"
                  >
                    No machines to display
                  </td>
                </tr>
              )}
              {data.machines.map((machine) => (
                <tr key={machine.name}>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm font-medium leading-5 text-gray-900">
                    <a
                      {...routes.computeMachineDetail({
                        projectId: props.projectId,
                        machineId: machine.name.split('/')[3],
                      }).link}
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      {machine.name.split('/')[3]}
                    </a>
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm leading-5 text-gray-500">
                    <MachineStateBadge state={machine.state} />
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm leading-5 text-gray-500">
                    {machine.systemInfo?.operatingSystem?.id || '\u2014'}
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm leading-5 text-gray-500">
                    {machine.systemInfo?.operatingSystem?.versionId ||
                      '\u2014'}
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm leading-5 text-gray-500">
                    {machine.systemInfo?.kernel?.architecture || '\u2014'}
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm text-right leading-5 text-gray-500">
                    {machine.systemInfo?.hardware?.vcpuCount ||
                      '\u2014'}
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-right text-sm leading-5 text-gray-500">
                    {humanFileSize(
                      machine.systemInfo?.hardware?.totalMemoryBytes,
                    ) || '\u2014'}
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
                    <TimeAgo
                      datetime={timestampDate(machine.createTime)}
                      opts={{ minInterval: 10 }}
                    />
                  </td>
                  <td className="w-full whitespace-nowrap border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
                    <MachineKebab
                      projectId={props.projectId}
                      machineId={machine.name.split('/')[3]}
                      mutate={mutate}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {data.machines.length > 0 && (
            <PaginationFooter
              totalSize={data.totalSize}
              pageSize={data.machines.length}
              startIndex={data.startIndex}
              previousPageLink={routes.computeMachineList({
                projectId: props.projectId,
                pageToken: data.previousPageToken,
              }).link.href}
              nextPageLink={routes.computeMachineList({
                projectId: props.projectId,
                pageToken: data.nextPageToken,
              }).link.href}
            />
          )}
        </div>
      </div>
    </div>
  );
}

function humanFileSize(s: bigint) {
  if (!s) {
    return '';
  }
  let size = BigInt(s);
  if (size < 1024) {
    return size + ' B';
  }

  let divisor = Number(size / BigInt(1073741824));
  let remainder = Number(size % BigInt(1073741824));

  let num = (divisor + (remainder / 1073741824)).toFixed(2);

  return `${num} GiB`;
}
