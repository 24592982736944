import { timestampDate } from '@bufbuild/protobuf/wkt';
import TimeAgo from 'timeago-react';

import { useListGitRepositories } from '../../hooks/use_delivery_api';
import { routes } from '../../router';
import { PaginationFooter } from '../core/pagination_footer';
import { GitRepoKebab } from './git_repo_kebab';
import { GitRepoStateBadge } from './git_repo_state_badge';

interface GitRepoTableProps {
  projectId: string;
}

export function GitRepoTable(props: GitRepoTableProps) {
  const params = new URLSearchParams(window.location.search);
  const pageToken = params.get('pageToken');
  const { data, mutate } = useListGitRepositories({
    parent: `projects/${props.projectId}`,
    pageSize: 15,
    pageToken: pageToken === null ? '' : pageToken,
  });
  return (
    <div className="mt-4 flex flex-col">
      <div className="-my-2 overflow-x-auto py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div className="inline-block min-w-full overflow-hidden align-middle">
          <table className="min-w-full">
            <thead>
              <tr>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Name
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  State
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  URI
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                  Refreshed
                </th>
                <th className="border-b border-gray-200 bg-gray-100 px-6 py-3 text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-600">
                </th>
              </tr>
            </thead>
            <tbody className="bg-white">
              {data.gitRepositories.length == 0 && (
                <tr>
                  <td
                    colSpan={5}
                    className="whitespace-nowrap border-b border-gray-200 px-6 py-3 text-sm leading-5 text-gray-500"
                  >
                    No Git repositories to display
                  </td>
                </tr>
              )}
              {data.gitRepositories.map((repo) => (
                <tr key={repo.name}>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-sm font-medium leading-5 text-gray-900">
                    <a
                      {...routes.gitRepositoryDetail({
                        projectId: props.projectId,
                        gitRepositoryId: repo.name.split('/')[3],
                      }).link}
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      {repo.name.split('/')[3]}
                    </a>
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
                    <GitRepoStateBadge state={repo.state} />
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
                    {repo.uri}
                  </td>
                  <td className="whitespace-nowrap border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
                    <TimeAgo
                      datetime={timestampDate(repo.refreshTime)}
                      opts={{ minInterval: 10 }}
                    />
                  </td>
                  <td className="w-full whitespace-nowrap border-b border-gray-200 px-6 py-2 text-left text-sm leading-5 text-gray-500">
                    <GitRepoKebab
                      projectId={props.projectId}
                      repoId={repo.name.split('/')[3]}
                      mutate={mutate}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {data.gitRepositories.length > 0 && (
            <PaginationFooter
              totalSize={data.totalSize}
              pageSize={data.gitRepositories.length}
              startIndex={data.startIndex}
              previousPageLink={routes.gitRepositoryList({
                projectId: props.projectId,
                pageToken: data.previousPageToken,
              }).link.href}
              nextPageLink={routes.gitRepositoryList({
                projectId: props.projectId,
                pageToken: data.nextPageToken,
              }).link.href}
            />
          )}
        </div>
      </div>
    </div>
  );
}
